@import '@bit/modus-moodys.mapulseui._vars';
.panel-content-wrapper {
    .manage-watchlists-create-new {
        .label {
            padding-top: 40px;
            padding-bottom: 10px;
            display: block;
        }
        .ma-pulse-ui-input-field {
            max-width: 300px;
            display: block;
            margin-bottom: 30px;
        }
        .btn-container {
            > * {
                display: inline-block;
                vertical-align: middle;
            }
            .ma-pulse-ui-submit-button {
                // margin-left: 10px;
            }
            .secondary-text-link {
                appearance: none;
                border: none;
                background: none;
                margin-left: 20px;
                padding: 10px 20px;
            }
        }
        .message {
            display: block;
            position: absolute;
            bottom: 50px;
            left: 10px;
        }
        .success-message {
            color: #59d58d;
        }
    }
    .manage-watchlists-add-entities-to-watchlist {
        .title {
            font-size: 36px;
        }
        .edit-watchlist-name {
            background-color: transparent;
            border: none;
            svg {
                width: 15px;
            }
        }
        .ma-pulse-ui-paginated-list {
            width: calc(100% - 10px);
            min-width: calc(100% - 10px);
        }
        .ma-pulse-ui-paginated-list-wrapper {
            //max-height:60vh;
            max-height: calc(100vh - 400px); //51px + 60px + 82px + 85px + 52px
            overflow-y: auto;
            overflow-x: hidden;
            .ma-pulse-ui-watchlist-entity-row {
                border-left: none;
                border-right: none;
                &:not(:first-child) {
                    border-top: none;
                }
            }
        }
        .paginated-list-container {
            border-top: 1px solid $table-line-color;
            padding-top: 10px;
            margin-top: 20px;
            position: relative;
        }

        .checkbox-bulk-handlers {
            position: absolute;
            right: 0;
            top: 5px;
            z-index: 5;

            button {
                background-color: white;
                border: none;
                cursor: pointer;
            }
        }
        .inline-button {
            display: inline-block;
            vertical-align: middle;
            width: 100px;
            margin-left: calc(100% - 340px);
        }
        .ma-pulse-ui-input-text-with-icon {
            display: inline-block;
            vertical-align: middle;
        }
        .subtext {
            padding-bottom: 30px;
            color: $primary-text-color;
            font-weight: 200;
            font-size: 18px;
            font-family: $secondary-font;
            border-bottom: 1px solid $table-line-color;
            margin-bottom: 20px;
        }
    }

    .manage-watchlists-edit-or-remove {
        .paginated-list-row {
            border-left: none;
            border-right: none;
        }
        .ma-pulse-ui-paginated-list-wrapper {
            //max-height:60vh;
            max-height: calc(100vh - 374px); //51px + 60px + 82px + 85px + 52px
            overflow-y: auto;
            overflow-x: hidden;
        }
        .ma-pulse-ui-paginated-list {
            .ma-pulse-ui-paginated-list-wrapper {
                border-right: none;
                border-left: none;
            }
        }
        .ma-pulse-ui-input-text-with-icon {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    .manage-watchlists-delete {
        .subtext {
            padding-bottom: 30px;
            color: $primary-text-color;
            font-weight: 200;
            font-size: 18px;
            font-family: $secondary-font;
        }
        .text-link {
            appearance: none;
            border: none;
            background: none;
            padding-left: 20px;
        }
    }

    .manage-watchlists-edit {
        position: relative;
    }
}
