@import '@bit/modus-moodys.mapulseui._vars';
@import '../../App.scss';

$page-content-padding-vertical: 50px;
$page-content-padding-horizontal: 60px;

.page-details {
    min-height: 100%;
    .no-click {
        pointer-events: none;
        cursor: pointer;
    }
    .title-ribbon {
        padding: 0 45px 0 45px;
        width: 100%;
        height: 170px;
        // border-top: 1px solid $table-line-color;
        border-bottom: 1px solid $table-line-color;
        background-color: white;
        box-sizing: border-box;
        .left-content,
        .right {
            & > * {
                margin-right: 4px;
            }
        }
        .two-col-title {
            display: flex;
            margin: unset;
            padding-top: 23px;
            padding-bottom: 37px;
            .left-content {
                display: flex;
                flex-direction: column;
                // NXTGN-239
                > :not(:first-child) {
                    margin-top: 6px;
                }
                margin-right: 20px;
                .heading-1 {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    max-width: 675px;
                }
            }
            .left-row-content {
                align-items: center;
            }
        }
        .right {
            // float: right;
            position: unset;
            display: flex;
            flex: 1;
            align-items: flex-end;
            // .count-display {
            //     margin-right: 35px;
            //     margin-top: 8px;
            // }

            > div {
                vertical-align: middle;
                line-height: 17px;
            }
        }
        .no-state {
            display: inline-block;
        }
    }
    .page-content {
        padding: $page-content-padding-vertical $page-content-padding-horizontal;
        min-height: 500px;

        .w-100 {
            width: 100%;
        }

        .top-container {
            display: inline-block;
            padding-bottom: 20px;

            .select-options {
                margin-bottom: 26px;

                .icon.folder {
                    height: 13px;
                    margin-right: 8px;
                }

                li {
                    display: inline-block;
                    margin-right: 38px;
                    font-size: 1.125em; //18px
                    font-family: $secondary-font;
                    font-weight: lighter;

                    &:nth-child(1) {
                        border-bottom: 3px $active-fill-color-2 solid;
                        padding-bottom: 4px;
                        font-weight: bold;
                    }
                    &:nth-child(3) {
                        color: $active-fill-color-2;
                    }
                }
            }
            .header-row {
                position: relative;
                height: 30px;

                > div {
                    position: absolute;
                }

                .articles-info {
                    left: 0;
                    font-size: 0.875em; //14px
                    span {
                        &:nth-child(1) {
                            color: $heading-text-color;
                        }
                        &:nth-child(2) {
                            color: $primary-text-color;
                        }
                    }
                    .btn-mark-read {
                        border: none;
                        padding: 10px 16px;
                        font-weight: normal;
                    }
                }
                .sort-by {
                    right: 0;
                    .article-sort-list-title,
                    .article-sort-list {
                        display: inline-block;
                        vertical-align: middle;

                        &:first-child {
                            margin-right: 8px;
                        }
                    }
                    .article-sort-list {
                        min-width: 280px;
                    }
                }
            }
        }

        .page-body {
            display: table;
            width: 100%;
            .page-table-row {
                display: table-row;
                .filters-container {
                    font-size: 1em;
                    .filters-header {
                        font-family: $secondary-font;
                    }
                    .filter-title-and-tooltip {
                        .filter-title {
                            font-size: 0.875em;
                        }
                    }
                    .ma-pulse-ui-button {
                        height: 20px;
                        width: 106px;
                        padding: unset;
                        font-weight: unset;
                        font-size: 0.8125em;
                        .button-content {
                            justify-content: center;
                        }
                    }
                }
                .risk-level-filter {
                    text-transform: uppercase;
                    .checkboxes-wrapper {
                        .secondary-text {
                            font-size: 0.75em;
                        }
                    }
                    .lbl-reset {
                        text-transform: capitalize;
                    }
                }
                .risk-category-filter {
                    text-transform: capitalize;
                    .checkboxes-wrapper {
                        span {
                            font-weight: 400;
                        }
                    }
                }

                .ma-pulse-ui-checkbox {
                    span {
                        font-size: 0.875em; //14px
                        vertical-align: middle;
                    }
                    &.high .ma-pulse-ui-checkbox__label-str {
                        color: $color-risk-high;
                    }
                    &.medium .ma-pulse-ui-checkbox__label-str {
                        color: $color-risk-med;
                    }
                    &.low .ma-pulse-ui-checkbox__label-str {
                        color: $color-risk-low;
                    }
                }
                > div {
                    display: table-cell;
                }
                .creditAdverse {
                    span {
                        color: #5c068c;
                    }
                }
                .negative {
                    span {
                        color: #e70031;
                    }
                }
            }

            .date-picker-filter {
                .filter-title-and-tooltip {
                    .lbl-reset {
                        pointer-events: all;
                    }
                    &.disabled {
                        .lbl-reset {
                            color: $secondary-text-color !important;
                            cursor: default;
                            pointer-events: none;
                        }
                    }
                }

                .dates-button {
                    cursor: pointer;
                    color: $primary-text-color;

                    &.default {
                        color: $secondary-text-color !important;
                    }

                    .icon.calendar {
                        margin-right: 8px;
                        vertical-align: top;
                        height: 17px;
                        .svg-fill {
                            fill: $active-fill-color-2;
                        }
                    }
                    > div {
                        vertical-align: middle;
                        display: inline-block;
                        &:nth-child(3) {
                            margin: 0 5px;
                        }
                    }
                    font-size: 16px;
                    margin-top: 12px;
                    display: flex;
                    align-items: center;
                }
            }
        }

        .articles-container {
            position: relative;
            .ma-pulse-ui-paginated-list-wrapper {
                border: none;
            }

            .details-skeleton-screen {
                border: 1px dashed #a2bcca;
                display: table;
                width: 70%;
                padding: 200px 50px;
                margin-left: auto;
                margin-right: auto;
                .cont {
                    display: table-cell;
                    vertical-align: middle;
                    text-align: center;
                    .heading-4 {
                        margin-bottom: 20px;
                    }
                    button {
                        padding: 10px 35px;
                    }
                }
            }

            .article-row-table {
                vertical-align: top;
                width: calc(100% - 40px);
                left: 40px;
                top: 0;
                bottom: 0;
                position: relative;
                .ma-pulse-ui-article-row {
                    &:not(:last-of-type) {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .ma-pulse-ui-css-display {
        .title,
        svg {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            margin-left: 5px;
        }
    }
    .article-folder-ribbon {
        .label-list-selected-option {
            font-family: $secondary-font;
            font-size: 1em; //16px
        }
    }
}
