@import '@bit/modus-moodys.mapulseui._colors';

.panel-content-wrapper {
    .manage-folder-create-new {
        .label {
            padding-top: 40px;
            padding-bottom: 10px;
            display: block;
        }
        .ma-pulse-ui-input-field {
            max-width: 300px;
            display: block;
            margin-bottom: 30px;
        }
        .btn-container {
            > * {
                display: inline-block;
                vertical-align: middle;
            }
            .secondary-text-link {
                appearance: none;
                border: none;
                background: none;
                margin-left: 20px;
                padding: 10px 20px;
            }
        }
        .message {
            display: block;
            position: absolute;
            bottom: 50px;
            left: 10px;
        }
        .success-message {
            color:#59D58D;
        }
    }
    .manage-folder-edit-remove {
        position: relative;

        .ma-pulse-ui-input-text-with-icon {
            position: fixed;
            /* top: -150px; */
            top: 100px;
            right: 60px;
        }

        .folder-list-row {
            border-left: none;
            border-right: none;
            .folder-remove {
                button {
                    height: 32px;
                }
            }
        }
        .ma-pulse-popper {
            transform: translateX(-15vw);
        }
        .ma-pulse-ui-paginated-list {
            .ma-pulse-ui-paginated-list-wrapper {
                max-height: calc(100vh - 266px);
                overflow: auto;
                border: none !important;
            }
        }
        .ma-pulse-ui-page-indicator {
            padding-top: 0px;
        }
    }
}
