@import '@bit/modus-moodys.mapulseui._vars';

.add-shortcuts-panel {
    padding-right: 93px;
    .title {
        // This should be a shared defined font
        font-size: 2.25em;
        margin: 20px 0;
    }
    .subheading {
        color: #55565a;
        font-weight: 200;
        // This should be a shared defined font
        font-size: 1.125em;
        font-family: $secondary-font;
    }
    .filtered-paginated-list {
        margin-top: 24px;
        .filtered-paginated-list-header {
            border-top: 1px solid #dbdad8;
            height: 76px;
            padding: 0;
            align-items: center;
            .filtered-paginated-list-header-selector {
              button {
                font-family: $primary-font;
                font-size: 0.875em;
              }
          }

        }
        .filtered-paginated-list-body {
            overflow-x: auto;
            .ma-pulse-ui-paginated-list-container {
                min-width: 1128px;
            }
            .filtered-paginated-list-header-selector {
                display: flex;
                height: 40px;
                align-items: center;
            }
            .ma-pulse-ui-table-header {
                // font-family: $secondary-font;
                // font-size: 0.875em;
                .tertiary-text {
                    font-family: $secondary-font;
                    font-size: 0.875em;
                }
                .filtered-paginated-list-table-header-0 {
                    min-width: 277.2px;
                    // padding-left: 45px;
                }
                .filtered-paginated-list-table-header-1 {
                    min-width: 170.1px;
                }
                .filtered-paginated-list-table-header-2 {
                    min-width: 75.6px;
                    text-align: center;
                }
                .filtered-paginated-list-table-header-3 {
                    min-width: 75.6px;
                    text-align: center;
                }
                .filtered-paginated-list-table-header-4 {
                    min-width: 75.6px;
                    text-align: center;
                }
                .filtered-paginated-list-table-header-5 {
                    min-width: 113.4px;
                    text-align: center;
                }
                .filtered-paginated-list-table-header-6 {
                    min-width: 113.4px;
                    text-align: center;
                }
                .filtered-paginated-list-table-header-7 {
                    min-width: 113.4px;
                    text-align: center;
                }
            }
            .action-container {
                display: none;
            }
            .async-checkable-item {
                border-left: 1px solid #dbdad8;
                border-right: 1px solid #dbdad8;
                &:first-child {
                    border-top: 1px solid #dbdad8;
                }
                .paginated-list-row {
                    border: none;
                }
            }
            .entity-empty {
                min-height: inherit;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .entity-error {
                min-height: inherit;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
