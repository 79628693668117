.list-view {
    .events-list-view{
        .ma-pulse-ui-paginated-list-wrapper{
            border-top: none;
        }
    }
    .event-row {
        &.paginated-list-row {
            border: none !important;
        }
        background-color: white;
        .main-container {
            border: none;
            .date-container {
                text-align: unset;
            }
        }
    }
    :not(:last-child) {
        margin-bottom: 10px;
    }
    .ma-pulse-ui-table-header {
        ul {
            display: flex;
            margin-left: 20px;
            margin-right: 40px;
        }
        .label-country {
            flex: 1 1;
        }
        .label-event-title {
            flex: 5 1;
        }
        .label-date {
            flex: 1 1;
        }
    }
}
