@import '@bit/modus-moodys.mapulseui._vars';
.thankyoupane {
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .title {
        height: 50px;
        font-size: 30px;
        font-family: $secondary-font;
        font-weight: 200;
        color: $primary-text-color;
    }
    .subtitle {
        height: 48px;
        width: 416px;
        text-align: center;
        font-size: 16px;
        // font-family: 'Metropolis', 'Proxima Nova Regular';
        line-height: 24px;
        color: $primary-text-color;
    }
}
