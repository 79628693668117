@import '@bit/modus-moodys.mapulseui._vars';

.event-tab-switcher {
    position: relative;
    height: 47px;
    background: #dfeff7;
    .event-tab-switcher__header {
        display: flex;
        align-items: center;
        padding-left: 23px;
        height: 100%;
    }
    .two-col-title .right {
        top: unset;
        display: flex;
    }
}
