@import '@bit/modus-moodys.mapulseui.theme';

$color-risk-high: #ed2a79;
$color-risk-med: #b869de;
$color-risk-low: #46b3de;

.ma-pulse-header {
    .header-menu-wrapper {
        display: flex;
        height: 50px;
        align-items: baseline;
        .language-selector {
            margin-left: 12px;
            & > * {
                line-height: 1.3;
            }
        }
    }
}
.side-nav {
    box-sizing: border-box;
    height: 100%; /* 100% Full-height */
    width: $side-nav-width;
    position: fixed;
    z-index: $layer-1; /* Stay in front */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #002e5d;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 114px; /* Place content 40px from the top */
}

.app {
    min-width: 1440px;
    font-family: $primary-font;

    .ma-pulse-ui-panel-wrapper {
        &.credit {
            z-index: $super-layer;
        }
    }
}
.folder-select-filter {
    .icon-wrapper {
        max-height: 18px;
    }
}
.folder-select-filter {
    .header {
        .title {
            font-size: 1em !important;
            margin-top: 0px;
            margin-bottom: 7px;
        }
    }
}
.articlePopupOverride {
    .article-popup-container {
        transform: translateX(-15vw);
    }
    .save-btn,
    .remove-btn {
        height: 30px;
    }
}

body {
    line-height: 1.3;

    &.modal-open {
        overflow: hidden;
    }
    // TODO: Remove this once we fix the handler issue
    .checkbox-bulk-handlers {
        opacity: 0;
        pointer-events: none;
    }

    /**
        color overrides for risk levels for credit pulse. Covers:
            - article page
            - article rows on entity details page
    **/
    .ma-pulse-ui-base-panel-box {
        .article-view-panel {
            .article-tags {
                .article-score-container {
                    width: 5%;
                    .article-score {
                        font-family: $primary-font;
                        height: 18px;
                        width: 52px;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 1px;
                        line-height: 18px;
                        text-align: center;
                    }
                }
                &.high {
                    border-left: solid $color-risk-high 5px !important;
                    .article-score-container {
                        .article-score {
                            color: $color-risk-high !important;
                        }
                    }
                }
                &.med {
                    border-left: solid $color-risk-med 5px !important;
                    .article-score-container {
                        .article-score {
                            color: $color-risk-med !important;
                        }
                    }
                }
                &.low {
                    border-left: solid $color-risk-low 5px !important;
                    .article-score-container {
                        .article-score {
                            color: $color-risk-low !important;
                        }
                    }
                }
            }
        }
    }

    .ma-pulse-ui-article-row {
        .divider {
            &.high {
                background-color: $color-risk-high !important;
            }
            &.medium {
                background-color: $color-risk-med !important;
            }
            &.med {
                background-color: $color-risk-med !important;
            }
            &.low {
                background-color: $color-risk-low !important;
            }
        }
        .article-risk-sentiment {
            &.high {
                color: $color-risk-high !important;
            }
            &.medium {
                color: $color-risk-med !important;
            }
            &.med {
                color: $color-risk-med !important;
            }
            &.low {
                color: $color-risk-low !important;
            }
        }
    }
    .text-container {
        .ma-pulse-ui-text-input {
            font-family: $primary-font;
            font-size: 1em;
        }
    }
    .ma-pulse-ui-input-text-with-icon {
      input{
        font-family: $primary-font;
        font-size: 1em;
      }
    }
    .map-view {
      .gm-style {
        font-family: $primary-font;
      }
    }
}
.footer-rich-text-container {
    font-size: 14px;
}

.filter-risk-level {
    .ma-pulse-ui-checkbox__label {
        display: flex;
        align-items: center;
    }
    .ma-pulse-ui-checkbox__label-str {
        text-transform: unset !important;
    }
}
.dynamic-tab-headers,
.tab-controller {
    font-family: $secondary-font;
    font-size: 18px;
}
