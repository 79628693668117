$tileview-margin: 0 -10px;
$drag-item-margin: 10px;

.tile-view {
    position: relative;
    .loading-container {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: white;
        background: white;
        opacity: 0.5;
    }
    .drag-drop-area {
        margin: $tileview-margin;
        box-sizing: content-box;
        .muuri-item{
            margin: $drag-item-margin;
        }
        .muuri-item-placeholder  {
            border: 1px dashed black;
            z-index: 1;
            background-color: #002e5d;
            opacity: 0.3;
        }
        .muuri-item-dragging, .muuri-item-releasing{
            z-index: 4;
        }
        .react-resizable {
            position: relative;
        }
        .react-resizable-handle {
        position: absolute;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-origin: content-box;
        box-sizing: border-box;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
        background-position: bottom right;
        padding: 0 3px 3px 0;
        }
        
        .react-resizable-handle-e {
        top: 50%;
        margin-top: -10px;
        cursor: ew-resize;
        right: 0;
        transform: rotate(315deg);
        }

        .resizable-container{
            position: relative;

            .dash-tile-saved-events {
                .config-spacing {
                    padding: 15px 35px; 
                }
                .map-view-section {
                    .map-view {
                        height: inherit;
                        border: none;
                    }
                }
            }
        }
        .resizable-placeholder{
            position: absolute;
            height: 100%;
            top: -1px;
            left: -1px;
            border: 1px dashed black;
            z-index: -1;
        }

    }
}
