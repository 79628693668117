@import '@bit/modus-moodys.mapulseui._vars';
@import '../../PageEntity/styles.scss';

.page-event-wrapper{
    @extend .page-entity;
    .two-col-title {
        padding-top: unset;
        padding-bottom: unset;
        .right {
            right: 50.77px;
        }
    }
    .event-tab-switcher {
        .two-col-title .right {
            right: 7px;
            > * {
                margin: 0 7px;
            }
        }
        margin-bottom: 21px;
    }
}