$title-border-color: #dbdad8;
.panel-content-wrapper {
    hr {
        margin-bottom: 26px;
        border: none;
        height: 1px;
        background-color: #dbdad8;
    }
    .panel3 {
        &:not(.page-divider) {
            width: 90%;
        }
    }
    .buttons-wrapper {
        padding-top: 30px;
        text-align: center;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .ma-pulse-ui-submit-button {
        display: inline-block;
        // margin-left: 5px;
        margin-right: 5px;
    }
    .confirmation-state {
        top: 30vh;
        position: absolute;
        left: 10%;
        right: 10%;
    }
    .onboard-search-action {
        display: flex;
    }
    .text-container {
        width: 300px;
        margin-right: 15px;
        .icon-search {
            margin-top: 8px;
        }
        .x {
            margin-top: 5px;
        }
    }

    .ma-pulse-ui-button {
        height: 40px;
    }
    .search-by-id {
        display: flex;
        .ma-pulse-ui-button {
            margin-right: 15px;
        }
    }
    .panel3 {
        .entity-onboard-stat {
            display: flex;
            flex-direction: column;
            margin: 20px 0px;
            .onboard-stat-item {
                margin-top: 4px;
                color: #55565a;
                font-size: 14px;
                height: 24px;
                .item-count {
                    color: black;
                    font-weight: bold;
                }
                .progress-check-btn {
                    border: none;
                    font-weight: normal;
                    height: inherit;
                }
            }
        }
        .response-stats {
            display: flex;
            flex-direction: column;
            .heading-6 {
                margin: 10px 0px;
            }
        }
        .buttons-wrapper {
            text-align: left;
            display: flex;
            .try-again {
                margin-right: 28px;
            }
        }
    }
    .OnBoardSearchByName {
        .onbaord-actions {
            display: flex;
        }
        .ma-pulse-ui-paginated-list-wrapper {
            max-height: calc(100vh - 250px);
            // overflow: auto;
            width: 90%;
        }
        .apply-button {
            font-size: 18px;
            font-weight: 400;
        }
        .no-lr-border .ma-pulse-ui-paginated-list-wrapper .paginated-list-row{
            border-left: none;
            border-right: none;
        }
    }

    .manage-watchlists-add-entities-to-watchlist {
        width: 90%;
    }

    .typeahead-panel {
        .typeahead-entity-search {
            min-height: 0;
        }
    }
    .text-input {
        width: 650px;
    }
}
