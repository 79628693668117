@import '@bit/modus-moodys.mapulseui._vars';

.page-support {
    min-height: 100%;
    .support-form {
        background: #ffffff;
        padding: 50px 60px;
        > *:not(.support-form-submit-button):not(.submit-error) {
            margin-bottom: 37px;
            width: 60%;
            min-width: 400px;
            max-width: 600px;
        }
        .ma-pulse-ui-form-wrapper {
            padding-top: 10px;

            .ma-pulse-ui-text-input::placeholder {
                color: $secondary-text-color !important;
            }

            .ma-pulse-ui-text-area {
                padding: 15px;
            }
            .ma-pulse-ui-text-area::placeholder {
                color: $secondary-text-color !important;
            }
        }

        .ma-pulse-ui-dropdown-list {
            border: 1px solid #aaaaaa;
            z-index: $layer-3;
            .placeholder {
                font-size: 1em !important;
            }
        }
        .support-form-submit-button {
            width: 200px;
        }
        .submit-error {
            color: red;
            margin-bottom: 20px;
        }
        * > .form-field-label {
            color: black !important;
        }
        .user-pref-btn {
            height: 40px;
            width: 200px !important;
            min-width: 0;
            margin-top: 50px;
            button {
                height: 40px;
            }
        }
        .support-uploader{
            .ma-pulse-ui-drop-zone{
                z-index: 1;
            }
            .ma-pulse-ui-error-wrapper.form-wrapper{
                position: relative;
                bottom: 17px;
                z-index: 0;
            }
            .down{
                margin-top: unset;
            }
        }
    }
    ::placeholder {
        font-family: $primary-font;
        color: $primary-text-color !important;
    }
    ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-family: $primary-font;
        color: $primary-text-color;
    }
    ::-moz-placeholder {
        /* Firefox 19+ */
        font-family: $primary-font;
        color: $primary-text-color;
    }
    :-ms-input-placeholder {
        /* IE 10+ */
        font-family: $primary-font;
        color: $primary-text-color;
    }
    :-moz-placeholder {
        /* Firefox 18- */
        font-family: $primary-font;
        color: $primary-text-color;
    }
}
