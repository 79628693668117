@import '@bit/modus-moodys.mapulseui._vars';

.page-splash {
    position: relative;
    min-height: 100vh;
    height: 100%;
    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .progress-line {
            min-width: 50vh;
        }
    }
}
