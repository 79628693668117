@import '@bit/modus-moodys.mapulseui._vars';

.component-separator {
    display: flex;
    align-items: center;
    .separator-line {
        color: $secondary-text-color;
    }
    > * {
        padding: 0 5px;
    }
}
