@import '@bit/modus-moodys.mapulseui._vars';
@import '../../../App.scss';
@import '../../PageDetails/styles.scss';

.page-single-event {
    @extend .page-details;

    .title-ribbon {
        .two-col-title {
            .heading-1 {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 675px;
                margin-left: 10px;
            }

            svg.pin-icon {
                width: 25px;
                height: 30px;
            }
        }
    }

    .theme-filter {
        text-transform: capitalize;

        .checkboxes-wrapper {
            span {
                font-weight: 400 !important;
            }
        }
    }
}