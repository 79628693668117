@import '@bit/modus-moodys.mapulseui._vars';

$color-switch-button-link: #095fc3;

.page-event {
    min-height: 100%;
    .page-content {
        padding: 21px 45px !important;
    }
    .events-found {
        font-family: $primary-font;
    }
    .switch-button {
        color: $color-switch-button-link;
        height: 32px;
        width: 59px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background: none;
        font-family: $secondary-font;
        font-size: 16px;
        font-weight: 200;
        line-height: 22px;
        &.isActive {
            color: white;
            background: #0061c4;
            font-size: 18px;
            font-weight: bold;
        }
        cursor: pointer;
    }
    .page-content {
        padding: 50px 60px;
        min-height: 500px;

        .left-tile,
        .right-tile {
            width: 614px;
            display: inline-block;
            background-color: white;
            display: inline-block;
        }
        .left-tile {
            margin-right: 30px;
        }
    }
}
