@import '@bit/modus-moodys.mapulseui._vars';

.fade-enter {
    opacity: 0.01;
    &.fade-enter-active {
        opacity: 1;
        transition: opacity 160ms ease-in;
    }
}

.fade-exit {
    opacity: 1;
    &.fade-exit-active {
        opacity: 0.01;
        transition: opacity 150ms ease-in;
    }
}
.route-section {
    width: 100%;
    min-height: calc(80vh - 60px);
    min-width: 1440px - $side-nav-width;
    .move-up {
        margin-bottom: '-=15px';
        transition: all 0.4s;
    }
}
