@import '@bit/modus-moodys.mapulseui._vars';

.tab-in-progress {
    .in-porgress-headers {
        display: flex;
        width: 100%;
        margin-bottom: 8px;
        .in-porgress-headers-wrapper {
            flex-basis: 55%;
            display: flex;
            justify-content: space-between;
            span {
                text-align: center;
            }
        }
    }
    .info-tool-tip {
        svg {
            width: 20px;
        }
    }
    &.noData {
        .no-filters {
            border-top: none !important;
        }
    }
    .progress-status-row {
        padding: 0px 30px;
    }
}
