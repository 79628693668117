@import '@bit/modus-moodys.mapulseui._vars';
$chart-height: 430px;

.tab-sentiment-analysis {
    position: relative;
    font-family: $primary-font;

    .date-label {
        position: absolute;
        font-family: $secondary-font;
        left: 50%;
        transform: translateX(-50%);
        font-style: italic;
        z-index: 1;
        font-size: 15px;
        :not(b) {
            font-weight: 300;
        }
        top: 425px;
    }

    // .controls {
    //     font-size: 1.2em;
    //     .option-item {
    //         min-height: 30px;
    //         margin-right: 30px !important;
    //     }
    // }

    .chart-area {
        position: relative;
        margin-top: 30px;
        min-height: 150px;
        background-color: white;
        border: 1px solid $table-line-color;

        .arrow {
            position: absolute;
            background-color: transparent;
            cursor: pointer;
            padding: 10px 5px;
            top: 0;
            margin-top: calc(#{$chart-height}/ 2 + 10px);

            &.disabled {
                pointer-events: none;
                background-color: lightgray;
                > .icon.chevron {
                    > path {
                        fill: white;
                    }
                }
            }

            &:hover {
                background-color: $active-fill-color-1;
                > .icon.chevron {
                    > path {
                        fill: white;
                    }
                }
            }

            &.left {
                left: 0;
                > .icon.chevron {
                    transform: rotate(90deg);
                }
            }

            &.right {
                right: 0;
                > .icon.chevron {
                    transform: rotate(-90deg);
                }
            }
        }

        .plot-area {
            height: $chart-height;
            margin: 50px;

            .series-checkbox {
                border: 1px solid $table-line-color;
                margin-top: 10px;
                padding: 15px;
                margin-right: 0 !important;

                &:first-child {
                    border-right: 0;
                }
            }
        }
    }
}
.tab-sentiment-analysis-container {
    position: relative;
    .overlay {
        background-color: white;
        opacity: 0.3;
        pointer-events: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}
