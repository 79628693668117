@import '@bit/modus-moodys.mapulseui._vars';

.panel-content-wrapper {
    padding-right: 90px;
    .manage-widget {
        .header {
            padding: 20px 0;
            display: block;
        }

        .seperator {
            margin-top: 20px;
        }

        .manage-paginated-watchlist {
            .paginated-list-row {
                border-left: none;
                border-right: none;
            }
        }

        .ma-pulse-ui-paginated-list-wrapper {
            //max-height:60vh;
            max-height: calc(100vh - 374px); //51px + 60px + 82px + 85px + 52px
            overflow-y: auto;
            overflow-x: hidden;
        }
        .ma-pulse-ui-paginated-list {
            .ma-pulse-ui-paginated-list-wrapper {
                border-right: none;
                border-left: none;
            }
        }

        .ma-pulse-ui-input-text-with-icon {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .location-filter-gradient {
            .line {
                width: 70%;
                padding: 15px 0;
                height: 35px;
            }
        }

        .location-filter-container {
            .location-filter-title {
                display: block;
                margin-bottom: 10px;
            }
            > .location-filter-body {
                padding: 10px 0;
            }
        }
        .location-radio-group {
            padding: 20px;
        }

        .buttons-wrapper {
            display: inline-block;
        }
    }
}
