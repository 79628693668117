@import '@bit/modus-moodys.mapulseui._vars';

.all-events-view {
    // display: flex;
    .all-events-view__body {
        display: flex;
    }
    .filters-container {
        width: 280px;
        font-size: 1em;
        .filter-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 28px;
            .filter-header__title {
                font-family: $secondary-font;
                height: 28px;
                width: 56px;
                color: #000000;
                font-size: 24px;
                font-weight: 200;
                letter-spacing: 0;
                line-height: 28px;
            }
            .ma-pulse-ui-button {
                height: 20px;
                width: 106px;
                padding: unset;
                font-weight: unset;
                font-size: 0.8125em;
                .button-content {
                    justify-content: center;
                }
            }
        }
        .date-picker-filter {
            .filter-title-and-tooltip {
                .lbl-reset {
                    pointer-events: all;
                }
                &.disabled {
                    .lbl-reset {
                        color: $secondary-text-color !important;
                        cursor: default;
                        pointer-events: none;
                    }
                }
            }

            .dates-button {
                cursor: pointer;
                color: $primary-text-color;

                &.default {
                    color: $secondary-text-color !important;
                }

                .icon.calendar {
                    margin-right: 8px;
                    vertical-align: top;
                    height: 17px;
                    .svg-fill {
                        fill: $active-fill-color-2;
                    }
                }
                > div {
                    vertical-align: middle;
                    display: inline-block;

                    &:nth-child(3) {
                        margin: 0 5px;
                    }
                }
                font-size: 16px;
                margin-top: 12px;
                display: flex;
                align-items: center;
            }
        }
        margin-right: 40px;
        flex: 0.25;
        .filter-by-region-section {
            .filter-title-section {
                padding-bottom: 10px;
            }
            .filter-country {
                width: 100%;
            }
        }
        .filter-title-and-tooltip {
            .filter-title {
                font-size: 0.875em;
            }
        }
        .filter-risk-level {
            text-transform: capitalize;
            .checkboxes-wrapper {
                span {
                    font-weight: 400;
                }
            }
        }
    }
    .shared-content-view {
        flex: 0.75;
    }
    .no-events-found {
        flex: 0.75;
        box-sizing: border-box;
        height: 138px;
        width: 940px;
        border: 1px dashed #a2bcca;
        display: flex;
        justify-content: center;
        align-items: center;
        .span {
            height: 22px;
            width: 137px;
            color: #000000;
            font-family: $secondary-font;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 22px;
        }
    }
}
