@import '@bit/modus-moodys.mapulseui._vars';

$border-bottom-height: 1px;
$row-height: 68px - $border-bottom-height;
$base-min-width: 1260px;
$name-width: 22%;
$location-width: 13.5%;
$dates-width: 9%;
$rdc-risk-profile-width: 9%;
$scores-width: 9.25%;
$css-score-width: 6%;
$daily-trend-width: 10%;
$css-delta-width: 8%;
$action-container-width: 4.2%;
$css-trendline-width: 10%;
$css-width: $dates-width + $dates-width + $action-container-width;
$css-score-left-border: $name-width + $location-width;
$risk-level-width: 6%;
$css-title-width: $risk-level-width * 3;
$adverse-title-width: $scores-width + $scores-width;
$css-right-buffer: -2px;
.page-portfolio {
    white-space: nowrap;
    min-height: 500px;
    position: relative;

    .survey-pane {
        white-space: normal;
    }

    .title-ribbon {
        height: 140px;
        padding: 0 45px 0 45px;
        width: 100%;
        position: relative;
        // border-top: 1px solid $table-line-color;
        border-bottom: 1px solid $table-line-color;
        background-color: white;
        box-sizing: border-box;
        .two-col-title {
            // height: 86px;
            .language-selector {
                margin-bottom: 6px;
            }
        }
        .right {
            float: right;
        }
        .dynamic-tab-headers {
            position: absolute;
            bottom: 0;
            .tab-handler {
                font-family: $secondary-font;
                font-size: 18px;
            }
            .label-list-options-wrapper {
                margin-top: 1.5em;
                outline: 2px solid #dbdad8;
                border-color: transparent;
                box-shadow: $box-shadow;
                .top-section-container {
                    border-color: $table-line-color;
                    padding-top: 10px;
                }
                .label-list-selected-option {
                    font-family: $secondary-font;
                    font-size: 18px;
                    font-weight: 200;
                }
            }
            button {
                text-decoration: none;
                font-size: 18px;
                font-weight: 200;
            }
        }
        .no-state {
            display: inline-block;
        }
    }
    .page-content {
        min-height: 600px;
        position: relative;
        max-width: 1440px;
        margin: 0px auto;
        .clipping-box {
            position: relative;
            overflow: hidden;

            .blocking-overlay {
                background: white;
                opacity: 0.25;
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                cursor: not-allowed;
            }
        }

        .no-entities-in-watchlist {
            border: 1px dashed #a2bcca;
            display: table;
            min-height: 200px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            .cont {
                display: table-cell;
                vertical-align: middle;
                text-align: center;
                .heading-4 {
                    margin-bottom: 20px;
                }
                button {
                    padding: 10px 35px;
                }
            }
        }
        .table-title-container {
            position: relative;
            height: 20px;
            background: $page-background-color;
            min-width: $base-min-width;
            box-sizing: border-box;
            .table-title {
                padding: 36px 0px 66px 38px;
            }
            .table-title-articles-found-note,
            .table-title-adverse-note {
                position: absolute;
                bottom: 0;
                // background-color: #f1f1f1;
                text-align: center;
                line-height: 20px;
                height: 20px;
                // padding: 5px 0px;
                // border: 1px solid #dbdad8;
            }
            .table-title-articles-found-note {
                left: -webkit-calc(#{$css-score-left-border});
                left: -moz-calc(#{$css-score-left-border});
                left: calc(#{$css-score-left-border});
                width: -webkit-calc(#{$css-title-width} - 2px);
                width: -moz-calc(#{$css-title-width}- 2px);
                width: calc(#{$css-title-width} - 2px);
                display: inline-flex;
                justify-content: center;
                border-bottom: 1px solid $table-line-color;
            }
            .table-title-adverse-note {
                left: -webkit-calc(
                    #{$css-score-left-border} + #{$css-title-width} - 1px
                );
                left: -moz-calc(
                    #{$css-score-left-border} + #{$css-title-width} - 1px
                );
                left: calc(
                    #{$css-score-left-border} + #{$css-title-width} - 1px
                );
                width: $adverse-title-width;
                width: -webkit-calc(#{$adverse-title-width} - 1px);
                width: -moz-calc(#{$adverse-title-width}- 1px);
                width: calc(#{$adverse-title-width} - 1px);
                border-left: 0.5px solid #dbdad8;
            }
        }
        .portfolio-entity-table {
            min-width: 1260px;
            background-color: white;
            .ma-pulse-ui-paginated-list-wrapper {
                * > .__react_component_tooltip {
                    opacity: 0.98 !important;
                }
            }
            .ma-pulse-ui-table-header {
                // border-bottom: 1px solid $table-line-color;
                // border-left: 1px solid $table-line-color;
                // border-right: 1px solid $table-line-color;
                // border
                background-color: $page-background-color;
                ul {
                    line-height: 24px;
                    li {
                        .tertiary-text {
                            color: $primary-text-color;
                            font-family: $secondary-font;
                            font-size: 14px;
                            letter-spacing: 0;
                            line-height: 20px;
                            height: 25px;
                        }
                    }
                }
            }
            .credit-sentiment-score {
                width: $css-score-width;
                min-width: $css-score-width * calc($base-min-width / 100%) !important;
                text-align: center !important; // padding-left: 20px;
            }
            .credit-delta-score {
                width: $css-delta-width;
                min-width: $css-delta-width * calc($base-min-width / 100%) !important;
                // padding-left: 20px;
                text-align: center !important;
            }
            .credit-daily-trend {
                width: $daily-trend-width;
                min-width: $daily-trend-width * calc($base-min-width / 100%) !important;
                text-align: center !important; // padding-left: 20px;
            }
            .is-company {
                min-width: $name-width * calc($base-min-width / 100%) !important;
                width: $name-width !important;
            }
            .location {
                min-width: $location-width * calc($base-min-width / 100%) !important;
                width: $location-width !important;
            }
            .last-viewed,
            .date-added,
            .last-news-found {
                min-width: $dates-width * calc($base-min-width / 100%) !important;
                width: $dates-width !important;
                text-align: center;
            }
            .rdc-risk-profile {
                min-width: $dates-width * calc($base-min-width / 100%) !important;
                width: $rdc-risk-profile-width !important;
                text-align: center;
            }
            .negative-score,
            .adverse-score {
                min-width: $scores-width * calc($base-min-width / 100%) !important;
                width: $scores-width !important;
                text-align: center !important;
            }
            .negative-score::after {
                content: ' ';
                height: 100%;
                width: 1px;
                position: absolute;
                top: 0;
                right: -webkit-calc(#{$css-width} + #{$css-right-buffer});
                right: -moz-calc(#{$css-width} + #{$css-right-buffer});
                right: calc(#{$css-width} + #{$css-right-buffer});
                -webkit-box-shadow: inset 1px 0 0 0 $table-line-color;
                box-shadow: inset 1px 0 0 0 $table-line-color;
            }
            .adverse-score::before {
                content: ' ';
                height: 100%;
                width: 1px;
                position: absolute;
                top: 0;
                right: -webkit-calc(
                    #{$css-width} + #{$scores-width} * 2 + #{$css-right-buffer}
                );
                right: -moz-calc(
                    #{$css-width} + #{$scores-width} * 2 + #{$css-right-buffer}r
                );
                right: calc(
                    #{$css-width} + #{$scores-width} * 2 + #{$css-right-buffer}
                );
                -webkit-box-shadow: inset -1px 0 0 0 $table-line-color;
                box-shadow: inset -1px 0 0 0 $table-line-color;
            }
            .css-score {
                width: $css-score-width !important;
                min-width: $css-score-width * calc($base-min-width / 100%) !important;
            }
            .css-delta-content {
                width: $css-delta-width !important;
                min-width: $css-delta-width * calc($base-min-width / 100%) !important;
            }
            .css-trendline {
                width: $css-trendline-width !important;
                min-width: $css-trendline-width * calc($base-min-width / 100%) !important;
            }
            .action-container {
                width: $action-container-width !important;
                min-width: $action-container-width *
                    calc($base-min-width / 100%) !important;
            }
            .action-container.rdc-risk-profile {
                width: $rdc-risk-profile-width !important;
            }
        }
    }
    .dynamic-tab-headers {
        .tab-header {
            display: inline-block;
            font-weight: 400;
            appearance: none;
            background: none;
            font-family: $secondary-font;
        }
        .label-list-selected-option {
            font-family: $secondary-font;
            font-size: 18px;
        }
        btn {
            &.tab-handler {
                font-size: 18px;
            }
        }
        .tab-style {
            display: inline-block;
            height: 50px;
            vertical-align: bottom;
            box-sizing: border-box;
        }
        .link-style {
            padding-bottom: 12px;
            border-bottom: 4px solid transparent;
        }
        .label-list-selected-option {
            line-height: 16px;
        }
        .space-right {
            display: inline-block;
        }
        & > .active {
            border-bottom: 4px solid black;
            .tab-header,
            &.tab-header {
                font-weight: 700;
            }
        }
    }
}

.ma-pulse-ui-panel-wrapper.visible {
    position: fixed;
    .ma-pulse-ui-paginated-list {
        min-width: 0;
    }
}
