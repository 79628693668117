@import '@bit/modus-moodys.mapulseui._vars';

.page-copyright {
    font-weight: normal;
    background-color: #ffffff;
    .copyright {
        margin: 45px;
    }
    p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
}
