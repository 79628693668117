@import '@bit/modus-moodys.mapulseui._vars';
.article-view-panel {
    .title {
        font-size: 36px;
        margin: 20px 0;
    }
    .article-actions {
        display: flex;
    }
    .separator {
        margin-bottom: 30px;
    }
    .ma-pulse-ui-article-highlight-tip {
        margin-left: -100px;
        position: absolute;
        bottom: 0;
        .left-content {
            img {
                width: 100%;
            }
        }
    }
    .ma-pulse-popper {
        transform: translateX(-15vw);
    }
    .folder-select-filter {
        .footer {
            button {
                height: unset;
            }
        }
    }
}
