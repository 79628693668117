@import '@bit/modus-moodys.mapulseui._colors';

$vertical-component-gap: 50px;
$generic-tile-width: 615px;
$grid-column-gap: 29px;

.page-dashboard .page-content {
    > :not(:last-child) {
        margin-bottom: $vertical-component-gap;
    }
    .add-tiles {
        margin-top: $vertical-component-gap;
        width: 100%;
        height: 66px;
        background-color: white;
        -webkit-box-shadow: 0px 0px 10px 0px #d2dce0;
        box-shadow: 0px 0px 10px 0px #d2dce0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .add-tile-popper {
        background: white;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 300px;
        box-shadow: 0 0 10px 10px #d2dce0;
        box-sizing: border-box;
        padding: 10px;
        z-index: 9999999;
        display: flex;
        justify-content: space-around;
        flex-direction: column;

        .tile-icon {
            padding: 5px;
            text-align: left;
            .icon {
                width: 25px;
                height: 25px;
            }
        }
    }
    .dash-tile-shortcut-empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
