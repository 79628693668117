@import '@bit/modus-moodys.mapulseui._vars';

.page-entity {
    min-height: 100%;
    .title-ribbon {
        padding: 0 45px 0 45px;
        width: 100%;
        height: 170px;
        // border-top: 1px solid $table-line-color;
        border-bottom: 1px solid $table-line-color;
        background-color: white;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .right {
            float: right;
        }
        .title-header {
            font-size: 36px;
            color: $black;
            margin-bottom: 20px;
            font-family: $secondary-font;
            color: $primary-text-color;
            font-weight: lighter;
        }
        .dynamic-tab-headers {
            .tab-handler {
                font-size: 1em;
            }
            button {
                text-decoration: none;
                font-weight: 200;
            }
        }
        .no-state {
            display: inline-block;
        }
    }
    .page-content {
        padding: 50px 60px;
        min-height: 500px;

        .left-tile,
        .right-tile {
            width: 614px;
            display: inline-block;
            background-color: white;
            display: inline-block;
        }
        .left-tile {
            margin-right: 30px;
        }
    }
}
